import basibm from "../assets/img/keys/profiles/BASI-BM.png";
import basivb from "../assets/img/keys/profiles/BASI_VB.png";
// import abu91_ from "../assets/img/keys/profiles/ABU_91.png";
import abu91 from "../assets/img/keys/profiles/ABU91FA.png";
import basiv from "../assets/img/keys/profiles/BASI_V.png";
// import ci48_ from "../assets/img/keys/profiles/CI_48.png";
import ci48 from "../assets/img/keys/profiles/Praegerohling_CI48_FA_Kopf.png";
import agb1 from "../assets/img/keys/profiles/FA_Kopf_Rohling_AGB-1.png";
import ff1d from "../assets/img/keys/profiles/FA_Kopf_Rohling_FF-1D.png";
import u4d from "../assets/img/keys/profiles/FA_Kopf_Rohling_U-4D.png";
import v55 from "../assets/img/keys/profiles/V55.png";

import is15 from "../assets/img/keys/profiles/IS_FA_NEW.png";
import fla from "../assets/img/keys/profiles/FLA.png";
import abu17d from "../assets/img/keys/profiles/ABU-17D.png";
import abu86d from "../assets/img/keys/profiles/ABU-86D.png";
import bk7d from "../assets/img/keys/profiles/BK-7D.png";
import ce53d from "../assets/img/keys/profiles/CE-53D.png";
import wil66d from "../assets/img/keys/profiles/WIL-66D.png";
import win22 from "../assets/img/keys/profiles/WIN-22.png";

type IKeyOriginalImages = {
  [name: string]: string;
};

export const FA_ORIGINAL_KEYS_IMAGES: IKeyOriginalImages = {
  "basi-bmfa": v55,
  "basi-vbfa": v55,
  "abu-91fa": v55,
  "basi-vfa": basiv,
  "ci-48fa": v55,
  "is-15fa": is15,
  flafa: fla,
  "agb-1fa": agb1,
  "ff-1dfa": ff1d,
  "u-4dfa": u4d,
};

export const ORIGINAL_KEY_IMAGES: IKeyOriginalImages = {
  "abu-17d": abu17d,
  "abu-86d": abu86d,
  "bk-7d": bk7d,
  "ce-53d": ce53d,
  "wil-66d": wil66d,
  "win-22": win22,
};
