import faImage from "../assets/img/keys/profiles/FA-Kopf.png";
import faBackImage from "../assets/img/keys/profiles/FA-Kopf-b.png";
import ovImage from "../assets/img/keys/profiles/OV-Kopf.png";
import ovBackImage from "../assets/img/keys/profiles/OV-Kopf-b.png";
import { IKeysBlankNames } from "../constants/pragerohlinge";
import { ORIGINAL_KEY_IMAGES } from "../constants/generateImage";
import { IKeySideName } from "../store/keysTypes";

interface IProps {
  blank: string;
  profile: string;
  side?: string;
}

const getKeyImage = ({ profile, blank, side }: IProps) => {
  if (blank === IKeysBlankNames.FA) {
    return {
      image: side === IKeySideName.BACK ? faBackImage : faImage
    };
  }

  if (blank === IKeysBlankNames.OV) {
    return {
      image: side === IKeySideName.BACK ? ovBackImage : ovImage
    };
  }

  return {
    image: ORIGINAL_KEY_IMAGES[profile] || ORIGINAL_KEY_IMAGES.abu17d,
  };
};

export default getKeyImage;
